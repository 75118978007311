/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'project',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/apps/dashboards',
            },
        ],
    },
    {
        id: 'apps',
        title: 'Accounts Management ',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:user-add',
        children: [
            {
                id: 'apps.ecommerce.inventory',
                title: 'Accounts',
                type: 'basic',
                link: '/apps/account',
            },
        ],
    },
    {
        id: 'apps',
        title: 'Products ',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        children: [
            {
                id: 'apps.signals',
                title: 'Signals',
                type: 'basic',
                link: '/apps/signals',
            },
            {
                id: 'apps.blends',
                title: 'Blends',
                type: 'basic',
                link: '/apps/blends',
            },
            {
                id: 'apps.routines',
                title: 'Routines',
                type: 'basic',
                link: '/apps/routines',
            },           
        ],
    },
    {
        id: 'apps',
        title: 'Memberships ',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:currency-dollar',
        children: [          
            {
                id: 'apps.features',
                title: 'Features Flags',
                type: 'basic',
                link: '/apps/features',
            },   
            {
                id: 'apps.membershiplevels',
                title: 'Membership Levels',
                type: 'basic',
                link: '/apps/membership-levels',
            },   
            {
                id: 'apps.experiences',
                title: 'Experiences',
                type: 'basic',
                link: '/apps/experiences',
            }       
        ],
    },
    {
        id: 'apps',
        title: 'Feed ',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:tag',
        children: [
            {
                id: 'apps.categories',
                title: 'Categories',
                type: 'basic',
                link: '/apps/categories',
            },            
            {
                id: 'apps.tags',
                title: 'Tags',
                type: 'basic',
                link: '/apps/tags',
            },                   
        ],
    },
    {
        id: 'apps',
        title: 'App Management ',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:document',
        children: [
            {
                id: 'apps.appversion',
                title: 'App Version',
                type: 'basic',
                link: '/apps/appversion',
            },
            {
                id: 'apps.documents',
                title: 'Documents',
                type: 'basic',
                link: '/apps/documents',
            },
            {
                id: 'apps.devices',
                title: 'Devices',
                type: 'basic',
                link: '/apps/devices',
            },
        ],
    },    
    {
        id: 'manufacturing',
        title: 'Manufacturing ',
        subtitle: '',
        type: 'collapsable',
        icon: 'heroicons_outline:calculator',
        children: [
            {
                id: 'apps.manufacturing',
                title: 'Manufacturing',
                type: 'basic',
                link: '/apps/manufacturing',
            },
        ],
    },
   
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
